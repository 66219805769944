import request from '../request';
// 首页-商户统计
export function home_trans_data(params, showLoading) {
  return request({
    url: `/data/trans/home`,
    method: `get`,
    params,
    showLoading,
  });
}
export function home_dxm_save(data) {
  return request({
    url: `/dxm/api/loan/order/save/info`,
    method: `post`,
    data,
  });
}

export function home_lsy_data(params) {
  return request({
    url: `/lsy/api/entry/merchant/page`,
    method: `get`,
    params,
  });
}
export function home_lsy_bind(data) {
  return request({
    url: `/lsy/api/ma/bind`,
    method: `post`,
    data,
  });
}

export function home_dxm_data(params) {
  return request({
    url: `/dxm/api/page`,
    method: `get`,
    params,
  });
}

export function home_dxm_data_title(params) {
  return request({
    url: `/dxm/api/sum`,
    method: `get`,
    params,
  });
}

export function home_sgin_code(params) {
  return request({
    url: `/jhb/h5/login/getJhbSign`,
    method: `get`,
    params,
    showLoading: false,
  });
}

// 首页-代理统计
export function home_agent_data(params, showLoading) {
  return request({
    url: `/data/agent/home`,
    method: `get`,
    params,
    showLoading,
  });
}

// 首页-商户统计
export function home_merchant_data(params, showLoading) {
  return request({
    url: `/data/merchant/home`,
    method: `get`,
    params,
    showLoading,
  });
}

// 首页-激活统计
export function home_achieve_data(params, showLoading) {
  return request({
    url: `/data/achieve/home`,
    method: `get`,
    params,
    showLoading,
  });
}

// 首页-增值团队办卡数据
export function agent_va_member_performance(params, showLoading) {
  return request({
    url: `/va/member/performance`,
    method: `get`,
    params,
    showLoading,
  });
}

// 轮播图
export function home_advertisement_list(params, showLoading) {
  params.packageName = params.packageName ?? `com.rhb.app`;
  return request({
    url: `/operate/advertisement/list`,
    method: `get`,
    params,
    showLoading,
  });
}

// 掌银创客签名
export function home_userAgent(params, showLoading) {
  return request({
    url: '/user/agent/zyck',
    method: 'get',
    params,
    showLoading,
  });
}

// app内启动广告-轮播图查询
export function operate_advertisement_list(params, showLoading) {
  return request({
    url: '/operate/advertisement/list',
    method: 'get',
    params,
    showLoading,
  });
}

// app广告-详情查询
export function operate_advertisement_info(params) {
  return request({
    url: `/operate/advertisement/info/${params.id}`,
    method: `get`,
    params,
  });
}
