export default [
  {
    path: '/superUpstream',
    redirect: '/superUpstream/index',
    component: {
      render() {
        return (
          <keep-alive include={this.$store.state.viewsCache.caches}>
            <router-view />
          </keep-alive>
        );
      },
    },
    children: [
      {
        path: 'index',
        name: 'lkl',
        component: () => import('@/views/superPages/lkl'),
      },
      {
        path: 'lsyShare',
        name: 'lsyShare',
        component: () => import('@/views/superPages/lkl/lsyShare.vue'),
      },
      {
        path: 'dxmdk',
        name: 'dxmdk',
        component: () => import('@/views/superPages/dxmdk/index.vue'),
      },
      {
        path: 'dxmdkShare',
        name: 'dxmdkShare',
        component: () => import('@/views/superPages/dxmdk/dxmdkShare.vue'),
      },
      
    ],
  },
];