import request from '../request';

export function upload_share_image(data) {
  return request({
    url: `/user/agent/img/share/upload`,
    method: `post`,
    headers: {
      'Content-Type': `multipart/form-data`,
    },
    data,
  });
}



export function login(data) {
  return request({
    url: `/login`,
    method: `post`,
    data,
  });
}

export function logout() {
  localStorage.clear();
  localStorage.setItem('isAgree', true);
  return request({
    url: `/login/logout`,
    method: `post`,
  });
}

// 注销
export function cancel() {
  return request({
    url: `/login/cancel`,
    method: `post`,
  });
}

// 协议获取
export function agreement_info(params, showLoading) {
  return request({
    url: `/operate/agreement/info`,
    method: `get`,
    params,
    showLoading,
  });
}

// 版本获取
export function verison_info(params) {
  return request({
    url: `/operate/version/info`,
    method: `get`,
    params,
  });
}

// 修改手机号
export function login_update_phone(data) {
  return request({
    url: `/login/update/phone`,
    method: `post`,
    data,
  });
}

// 校验验证码
export function verify_captcha(data) {
  return request({
    url: `/login/verifySmsCaptcha`,
    method: `post`,
    data,
  });
}

// 校验验证码 - 新
export function new_verify_captcha(data) {
  return request({
    url: `/login/verifySmsCaptcha/pwd`,
    method: `post`,
    data,
  });
}

// 重置密码
export function home_account_safe_password_reset(data) {
  return request({
    url: `/home/account/safe/password/reset`,
    method: `post`,
    data,
  });
}

// 注册
export function login_registered(data) {
  return request({
    url: `/login/registered`,
    method: `post`,
    data,
  });
}

// 发送短信验证码
export function login_send_captcha(data) {
  return request({
    url: `/login/send/captcha`,
    method: `post`,
    data,
  });
}

// 根据服务商ID获取用户信息
export function login_find_recCode(params) {
  return request({
    url: `/login/find/recCode`,
    method: `get`,
    params,
  });
}

// 找回密码
export function login_find_password(data) {
  return request({
    url: `/login/find/password`,
    method: `post`,
    data,
  });
}

// 已登录找回密码
export function login_password(data) {
  return request({
    url: `/login/password`,
    method: `post`,
    data,
  });
}

// 根据代理id获取代理详情
export function user_agent_info(agentId) {
  return request({
    url: `/user/agent/get/${agentId}`,
    method: `get`,
  });
}

// 获取代理角色
export function user_agent_role(showLoading) {
  return request({
    url: `/user/agent/role`,
    method: `get`,
    showLoading,
  });
}

// 获取代理支付宝
/* export function user_agent_get_Alipay(agentId) {
  return request({
    url: `/user/agent/getAlipay/${agentId}`,
    method: 'get',
  })
} */

// 账户明细
export function get_account_flow(params, showLoading) {
  return request({
    url: `/account/info/list`,
    method: `get`,
    params,
    showLoading,
  });
}

// 账户详情
export function get_account_detail(orderNo) {
  return request({
    url: `/account/info/info/${orderNo}`,
    method: `get`,
  });
}

// 代理账户信息
export function get_account_info(agentId, showLoading) {
  return request({
    url: `/account/info/agent`,
    method: `get`,
    showLoading,
  });
}

// 代理账户余额
export function get_account_total_amount(agentId, showLoading) {
  return request({
    url: `/account/info/amount`,
    method: `get`,
    showLoading,
  });
}

// 我的-优惠券
export function market_coupon_my(params) {
  return request({
    url: `/market/coupon/my`,
    method: `get`,
    params,
  });
}

// 我的-优惠券使用情况
export function market_coupon_my_head(params) {
  return request({
    url: `/market/coupon/my/head`,
    method: `get`,
    params,
  });
}

// ------------------------------------签约 提现----------------------------------------------

// 签约云汇算
export function agent_sign_yhs() {
  return request({
    url: `/account/withdrawal/sign/yhs`,
    method: `get`,
  });
}

// 查询代理支付宝账号
export function user_agent_get_Alipay() {
  return request({
    url: `/account/withdrawal/alipay/info`,
    method: `get`,
  });
}

// 查询代理结算银行卡信息
export function user_agent_get_bankcard() {
  return request({
    url: `/account/withdrawal/bankcard/info`,
    method: `get`,
  });
}

// 添加支付宝
export function user_agent_add_Alipay(data) {
  return request({
    url: `/account/withdrawal/alipay/add`,
    method: `post`,
    data,
  });
}

// 代理提现
export function user_agent_withdraw(data) {
  return request({
    url: `/account/withdrawal/agent`,
    method: `post`,
    data,
  });
}

// 提现说明
export function account_withdrawal_orgPlan() {
  return request({
    url: `/account/withdrawal/agent/plan`,
    method: `get`,
  });
}

// ------------------------------------个人信息----------------------------------------------

// 个人信息
export function agent_info() {
  return request({
    url: `/user/agent/info`,
    method: `get`,
  });
}

// 个人信息-身份证信息
export function agent_idCard() {
  return request({
    url: `/user/agent/idCard`,
    method: `get`,
  });
}

// 个人信息-银行卡信息
export function agent_bankCard() {
  return request({
    url: `/user/agent/bankCard`,
    method: `get`,
  });
}

// 个人信息-修改银行卡信息
export function modify_agent_bankCard(data) {
  return request({
    url: `/user/agent/bankCard/update`,
    method: `post`,
    data,
  });
}

// 个人信息-查询支付宝账号
export function agent_alipay() {
  return request({
    url: `/user/agent/alipay`,
    method: `get`,
  });
}

// 个人信息-删除支付宝账号
export function delete_agent_alipay(userId) {
  return request({
    url: `/user/agent/delete/${userId}`,
    method: `post`,
  });
}

// 个人信息-修改支付宝账号
export function update_agent_alipay(data) {
  return request({
    url: `/user/agent/update`,
    method: `post`,
    data,
  });
}

// 个人信息-开通环球客账号
export function open_hqk(data) {
  return request({
    url: `/user/agent/open/hqk`,
    method: `post`,
    data,
  });
}

// 个人信息-开通开通宝账号
export function open_ktb(data) {
  return request({
    url: `/user/agent/open/ktb`,
    method: `post`,
    data,
  });
}

// 获取文件访问地址
export function get_file_url(params) {
  return request({
    url: `/common/file/url`,
    method: `get`,
    params,
  });
}

// 下级代理-列表
export function user_agent_descendant(params) {
  return request({
    url: `/user/agent/descendant`,
    method: `get`,
    params,
  });
}

// 添加支付宝获取信息
export function getAgentEcho(params) {
  return request({
    url: `/user/agent/echo`,
    method: `get`,
    params,
  });
}

// 地理位置
export function login_location(data) {
  return request({
    url: `/login/location`,
    method: `post`,
    data,
  });
}

// 贷款计算器详情获取个人数据
export function getAgentEchoDes(params) {
  return request({
    url: `/user/agent/echo/des`,
    method: `get`,
    params,
  });
}

// 贷款计算器详情获取个人数据
export function dataTransget06Jgj(params) {
  return request({
    url: `/data/trans/get06Jgj`,
    method: `get`,
    params,
  });
}

// 代理注销账号
export function user_agent_cancel(data) {
  return request({
    url: `/user/agent/cancel`,
    method: `post`,
    data,
  });
}

//--------------------------------展业校验----------------------------------
// 融通金宝 - 展业校验;
export function data_merchant_v2_rtjb_check(data) {
  return request({
    url: `/data/merchant/v2/rtjb/check`,
    method: `post`,
    data,
  });
}
// 展业校验-机具验证
export function device_sn_biz_check(params) {
  return request({
    url: `/device/sn/biz/check`,
    method: `get`,
    params,
  });
}

//--------------------------------设备管理----------------------------------
// 设备管理 - 列表;
export function user_agent_device_list() {
  return request({
    url: `/user/agent/device/list`,
    method: `get`,
  });
}

// 设备管理 - 删除;
export function user_agent_device_delete(id) {
  return request({
    url: `/user/agent/device/delete/${id}`,
    method: 'post',
  });
}

// 查询头像
export function user_agent_head_portrait_get(params) {
  return request({
    url: `/user/agent/head/portrait/get`,
    method: `get`,
    params,
  });
}

// 头像保存
export function user_agent_head_portrait_save(data) {
  return request({
    url: `/user/agent/head/portrait/save`,
    method: `post`,
    data,
  });
}

// 头像上传
export function user_agent_img_upload(data) {
  return request({
    url: `/user/agent/img/upload`,
    method: `post`,
    headers: {
      'Content-Type': `multipart/form-data`,
    },
    data,
  });
}

export const user_get_withdrawal_list = () => {
  return request({
    url: '/account/withdrawal/list',
    method: `get`,
  });
};
export const user_qr_code_list = (params) => {
  return request({
    url: '/direct/sale/qr/code/apply/page',
    method: `get`,
    params
  });
};

export const user_qr_code_save = (data) => {
  return request({
    url: '/direct/sale/qr/code/apply/save/info',
    method: `post`,
    data
  });
};

export const user_qr_code_detls = (params) => {
  return request({
    url: '/direct/sale/qr/code/depot/detail',
    method: `get`,
    params
  });
};

